import DangerButton from "@kamae-apps/shared/old/Component/Button/DangerButton"
import Divider from "@kamae-apps/shared/old/Component/Divider/Divider"
import { useCryptr } from "@killiangabrielkamae/cryptr-react"
import React from "react"
import { Briefcase, Clipboard, FileText, Film, Globe, Home, Image, Mail, Power, Tool, User, Users } from "react-feather"
import { useLocation, useNavigate } from "react-router-dom"
import Link from "./Link"

export default function Sidebar() {
  const location = useLocation()
  const cryptr = useCryptr()
  const navigate = useNavigate()
  return (
    <div className={"mr-2 flex w-[230px] min-w-[230px]"}>
      <div className={"flex grow flex-col items-center justify-between bg-white px-2 py-6"}>
        <div className={"mt-3"}>
          <h1 className={"text-primary text-2xl"}>Back-office</h1>
          <Divider className={"mt-2"} />
          <div className={"flex justify-center"}>
            <DangerButton
              className={"!p-1"}
              onClick={async () => {
                await cryptr.logOut(() => navigate("/login"), undefined, false)
                localStorage.clear()
              }}
            >
              <Power className={"no-fill h-4 w-4"} />
            </DangerButton>
          </div>
        </div>
        <div className={"flex w-full flex-col overflow-auto"}>
          <Link
            link={"/home"}
            location={location.pathname}
          >
            <Home className={"no-fill mr-2 inline"} />
            Supervision
          </Link>
          <Link
            link={"/user"}
            location={location.pathname}
          >
            <User className={"no-fill mr-2 inline"} />
            Utilisateurs
          </Link>
          <Link
            link={"/teams"}
            location={location.pathname}
          >
            <Users className={"no-fill mr-2 inline"} />
            Équipes
          </Link>
          <Link
            link={"/companies"}
            location={location.pathname}
          >
            <Briefcase className={"no-fill mr-2 inline"} />
            Entreprises
          </Link>
          <Link
            link={"/elearning"}
            location={location.pathname}
          >
            <Clipboard className={"no-fill mr-2 inline"} />
            E-Learnings
          </Link>
          <Link
            link={"/reflexe"}
            location={location.pathname}
          >
            <FileText className={"no-fill mr-2 inline"} />
            Réflexes
          </Link>
          <Link
            link={"/phishing"}
            location={location.pathname}
          >
            <Mail className={"no-fill mr-2 inline"} />
            Phishing
          </Link>
          <Link
            link={"/campaign"}
            location={location.pathname}
          >
            <Film className={"no-fill mr-2 inline"} />
            Scénarios
          </Link>
          <Link
            link={"/domains"}
            location={location.pathname}
          >
            <Mail className={"no-fill mr-2 inline"} />
            Domaines
          </Link>
          <Link
            link={"/avatar"}
            location={location.pathname}
          >
            <Image className={"no-fill mr-2 inline"} />
            Avatars
          </Link>
          <Link
            link={"/translation"}
            location={location.pathname}
          >
            <Globe className={"no-fill mr-2 inline"} />
            Traductions
          </Link>
          <Link
            link={"/toolbox"}
            location={location.pathname}
          >
            <Tool className={"no-fill"} />
            Boite à outils
          </Link>
        </div>
        <img
          src={"logo192.png"}
          className={"w-10"}
          alt={"logo"}
        />
      </div>
    </div>
  )
}
