import { Config } from "@killiangabrielkamae/cryptr-react"

const config = {
  api: import.meta.env.VITE_REACT_APP_API_URL ?? "https://api.kamae.io",
  app: import.meta.env.VITE_REACT_APP_APP_URL ?? "https://admin.kamae.io",
}

export const cryptrConf: Config = {
  audience: config.app,
  client_id: import.meta.env.VITE_REACT_APP_CRYPTR_CLIENT_ID ?? "3227c76a-0de5-47c8-b9ef-d37f86bd67ec", // Fallback to prod application
  default_redirect_uri: config.app,
  dedicated_server: true,
  default_slo_after_revoke: false,
  cryptr_base_url: "https://kamae.authent.me",
  tenant_domain: "kamae",
}

export default config
