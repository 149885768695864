import { APIError } from "@kamae-apps/shared/Types/API"
import { Scope } from "@kamae-apps/shared/Types/Scope"
import TUser from "@kamae-apps/shared/Types/TUser"
import { ToastContainer } from "@kamae-apps/shared/old/Component/Toast/Toast"
import { apiRequest, basicCompare } from "@kamae-apps/shared/utils"
import { useCryptr } from "@killiangabrielkamae/cryptr-react"
import { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const useApp = () => {
  const [user, setUser] = useState<TUser | null>(null)
  const [jwt, setJwt] = useState<string | null>(null)
  const [loaded, setLoaded] = useState<boolean>(false)
  const location = useLocation()
  const navigate = useNavigate()
  const cryptr = useCryptr()
  const toastContainerRef = useRef<ToastContainer>(null)

  const getUser = useCallback(async () => {
    if (!jwt) {
      return
    }
    try {
      const data = await apiRequest<TUser>("/user")
      if (!data) {
        return
      }
      if (user === null || !basicCompare(data, user)) {
        setUser(data)
      }
    } catch (err) {
      const errorsToCatch = [401, 403]
      if (errorsToCatch.includes((err as APIError).code)) {
        const redirectTo = () => {
          switch ((err as APIError).code) {
            case 401:
              return "/"
            case 403:
              return "/forbidden"
            default:
              return "/"
          }
        }

        if (cryptr.isAuthenticated()) {
          await cryptr.logOut(() => navigate(redirectTo()), undefined, false)
        }
        localStorage.clear()
      }
    }
  }, [jwt, user, cryptr, navigate])

  const RequestAccessTokenCryptr = useCallback(async () => {
    const token = cryptr.getCurrentAccessToken()
    setJwt(token ?? "")
    localStorage.setItem("jwt", token ?? "")
    await getUser()
  }, [cryptr, getUser])

  useEffect(() => {
    if (
      !cryptr.isAuthenticated() &&
      !cryptr.isLoading &&
      !["/unauthorized", "/forbidden", "/learning-page", "/bot"].includes(location.pathname)
    ) {
      navigate("/login")
    } else if (cryptr.isAuthenticated()) {
      RequestAccessTokenCryptr()
    }
  }, [cryptr, navigate, RequestAccessTokenCryptr, location.pathname])

  useEffect(() => {
    if (user !== null) {
      if (user.scope !== Scope.SUPER_ADMIN) {
        navigate("/forbidden")
        localStorage.clear()
      } else {
        setLoaded(true)
      }
    }
  }, [user, navigate])

  useEffect(() => {
    if (user !== null && user.scope === Scope.SUPER_ADMIN && location.pathname === "/") {
      navigate("/home")
    }
  }, [user, location.pathname, navigate])

  return {
    loaded,
    toastContainerRef,
  }
}
