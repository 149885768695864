import {
  WithTranslationProps,
  withComponentsNamespaceTranslation,
} from "@kamae-apps/shared/Wrappers/WithTranslation/withTranslation"
import clsx from "clsx"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { ChevronDown } from "react-feather"
import { ConditionalRender } from "../ConditionalRender/ConditionalRender"

export interface OptionInterface {
  value: string | number | readonly string[]
  name: JSX.Element | string | JSX.Element[]
}

export default withComponentsNamespaceTranslation(Select)

function Select(props: SelectProps & WithTranslationProps) {
  const { customTranslateFunction: t } = props
  const css = clsx(
    "bg-primary-50 relative z-10 mx-4 flex w-3/5 justify-between rounded p-4 px-4 py-3 outline-2 drop-shadow focus-within:outline",
    props.className,
    props.disabled ? "cursor-default" : "cursor-pointer"
  )
  const [open, setOpen] = useState(false)
  const optionCss = clsx(
    "custom-scrollbar bg-primary-50 absolute left-0 top-full flex w-full w-full cursor-default flex-col overflow-auto rounded-b",
    open ? "" : "hidden"
  )
  const container = useRef<HTMLDivElement>(null)
  const select = useRef<HTMLSelectElement>(null)
  const [value, setValue] = useState<string | number | readonly string[] | undefined>(props.defaultValue)
  const [name, setName] = useState(props.options.find(v => v.value === props.defaultValue)?.name ?? props.placeholder)
  const [color, setColor] = useState("text-gray-400")
  const [search, setSearch] = useState("")
  const labelCss = clsx(
    "bg-primary-50 absolute rounded p-0.5 text-gray-400 transition",
    name !== "" && "-translate-y-6 text-sm"
  )
  useEffect(() => {
    setColor(props.disabled ? "text-gray-300" : "text-gray-400")
  }, [props.disabled])
  const close = (e: MouseEvent) => {
    setOpen(prev => {
      if (container.current && !container.current.contains(e.target as Node) && prev) {
        return false
      }
      return prev
    })
  }
  const selectHandler = useCallback(
    (ev: KeyboardEvent) => {
      if (document.activeElement === select.current) {
        let newValueIndex = null
        if (ev.code === "ArrowUp") {
          ev.preventDefault()
          const valueIndex = props.options.findIndex(e => e.value === value)
          newValueIndex = valueIndex === 0 ? props.options.length - 1 : valueIndex - 1
        } else if (ev.code === "ArrowDown") {
          ev.preventDefault()
          const valueIndex = props.options.findIndex(e => e.value === value)
          newValueIndex = (valueIndex + 1) % props.options.length
        }
        if (newValueIndex !== null) {
          setValue(props.options[newValueIndex].value)
          setName(props.options[newValueIndex].name)
          if (select.current !== null) {
            select.current.value = props.options[newValueIndex].value.toString()
            select.current.dispatchEvent(new Event("change", { bubbles: true, cancelable: true }))
          }
        }
      }
    },
    [props.options, value]
  )

  const filteredOptions = props.options.filter(v => {
    const searchNorm = search
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
    const valueNorm = v.value
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
    const nameNorm = v.name
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
    return valueNorm.includes(searchNorm) || nameNorm.includes(searchNorm)
  })

  useEffect(() => {
    document.addEventListener("mousedown", close)
    document.addEventListener("keydown", selectHandler)
    return () => {
      document.removeEventListener("mousedown", close)
      document.removeEventListener("keydown", selectHandler)
    }
  }, [selectHandler])
  useEffect(() => {
    setSearch("")
  }, [open])
  useEffect(() => {
    if (name !== props.placeholder) {
      if (props.disabled) {
        setColor("text-gray-500")
      } else {
        setColor("text-black")
      }
    } else {
      setColor("text-gray-400")
    }
  }, [name, props.placeholder, props.disabled])
  useEffect(() => {
    setName(props.options.find(v => v.value === props.defaultValue)?.name ?? "")
  }, [props.defaultValue, props.placeholder, props.options])

  const chevronCss = clsx(
    "no-fill transition duration-200",
    open ? "rotate-180" : "rotate-0",
    props.disabled && "text-gray-300"
  )
  return (
    <div
      ref={container}
      className={css}
      onClick={() => {
        if (!props.disabled) {
          setOpen(!open)
        }
      }}
    >
      <label className={labelCss}>{props.placeholder}</label>
      <span className={color}>{props.options.find(v => v.value === props.defaultValue)?.name ?? name}</span>
      <select
        ref={select}
        className={"h-0 w-0"}
        onChange={props.onChange}
        value={value}
        disabled={props.disabled}
      >
        <option
          disabled
          value={-1}
        />
        {props.options.map((v, i) => (
          <option
            value={v.value}
            key={i}
          />
        ))}
      </select>
      <ChevronDown className={chevronCss} />
      <div
        className={optionCss}
        style={{
          maxHeight: ((props.maxLine ?? 3) + (props.searchable ? 1 : 0)) * 3 + "rem",
        }}
      >
        {props.searchable && (
          <div className={"bg-primary-50 sticky top-0 flex"}>
            <input
              value={search}
              onChange={e => setSearch(e.target.value)}
              onClick={e => e.stopPropagation()}
              className={
                "min-h-10 bg-primary-50 mx-2 my-1 grow rounded border-2 border-gray-400/50 pl-1 focus:outline-none"
              }
              placeholder={props.searchPlaceholder}
              data-rebranding={"search"}
            />
          </div>
        )}
        <span className="mx-4 text-sm text-gray-400">{t && t("components:select.listHeader")}</span>
        <ConditionalRender condition={filteredOptions.length > 0}>
          <>
            {filteredOptions.map((v, i) => (
              <Option
                key={i}
                value={v.value}
                onClick={() => {
                  setValue(v.value)
                  setName(v.name)
                  if (select.current !== null) {
                    select.current.value = v.value.toString()
                    select.current.dispatchEvent(new Event("change", { bubbles: true, cancelable: true }))
                  }
                }}
              >
                {v.name}
              </Option>
            ))}
          </>
        </ConditionalRender>
        <ConditionalRender condition={filteredOptions.length <= 0}>
          <span className="m-4">{t && t("components:select.noElementsToDisplay")}</span>
        </ConditionalRender>
      </div>
    </div>
  )
}

interface SelectProps {
  disabled?: boolean
  defaultValue?: string | number | readonly string[]
  className?: string
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  options: OptionInterface[]
  placeholder?: string
  name?: string
  maxLine?: number
  searchable?: boolean
  searchPlaceholder?: string
}

interface OptionProps {
  disabled?: boolean
  value?: string | number | readonly string[]
  children: any
  onClick: React.MouseEventHandler<HTMLDivElement>
}

function Option(props: OptionProps) {
  return (
    <div
      className={"min-h-12 flex cursor-pointer items-center rounded py-1 pl-4 hover:bg-gray-200"}
      onClick={props.onClick}
      data-rebranding={"option"}
    >
      {props.children}
    </div>
  )
}
