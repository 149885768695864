import { ChangeEventHandler, useMemo } from "react"
import { v4 } from "uuid"
import "./Slider.css"
import clsx from "clsx"

interface SliderProps {
  value?: string | number | readonly string[]
  checked?: boolean
  id?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  className?: string
  disabled?: boolean
  config?: string
}

export default function Slider(props: SliderProps) {
  const id = useMemo(() => props.id ?? v4(), [props.id])
  const config = useMemo(() => props.config ?? "primary medium", [props.config])

  return (
    <div
      className={clsx("flex items-center rounded-full focus-within:outline focus-within:outline-2", props.className)}
    >
      <input
        className={clsx(config, "kamae-slider h-0 w-0")}
        type={"checkbox"}
        value={props.value}
        checked={props.checked}
        id={id}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <label
        htmlFor={id}
        data-rebranding={`label-checked-${props.checked}`}
        className={"kamae-label disabled:bg-primary-200 relative block rounded-full bg-gray-200 transition-colors"}
      >
        <div className={"kamae-label-dot absolute left-1 top-1 rounded-full bg-white transition-all"} />
      </label>
    </div>
  )
}
