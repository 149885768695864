import clsx from "clsx"
import React, { HTMLInputTypeAttribute, useEffect, useRef, useState } from "react"
import "./Input.css"
import { v4 } from "uuid"

export interface InputProps {
  autoFocus?: boolean
  disabled?: boolean
  placeholder?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onEnterPress?: React.KeyboardEventHandler<HTMLInputElement>
  className?: string
  value?: string | number
  placeholderColor?: string
  name?: string
  type?: HTMLInputTypeAttribute
  onSearch?: React.ChangeEventHandler<HTMLInputElement>
  prefix?: string
  suffix?: string
}

export default function Input(props: InputProps) {
  const cssPlaceholder = clsx(
    "kamae-input-label bg-primary-50 absolute cursor-text rounded p-0.5 transition-all",
    props.placeholderColor || "font-gilroy-medium text-sm text-gray-400"
  )
  const css = clsx(
    props.className,
    "kamae-input bg-primary-50 relative mx-4 flex w-3/5 rounded py-3 drop-shadow focus:outline-none",
    props.value !== undefined && props.value !== "" && "non-empty"
  )
  const id = v4()
  const [timeoutId, setTimeoutId] = useState(0)
  const prefix = useRef<HTMLLabelElement>(null)
  const span = useRef<HTMLLabelElement>(null)
  const container = useRef<HTMLDivElement>(null)
  const [left, setLeft] = useState(0)
  useEffect(() => {
    const divPadding =
      container.current === null
        ? 0
        : parseInt(window.getComputedStyle(container.current).getPropertyValue("padding-left").slice(0, -2))
    const prefixLength = prefix.current === null ? 0 : prefix.current.clientWidth
    const textLength = span.current === null ? 0 : span.current.clientWidth
    setLeft(prefixLength + divPadding + textLength)
  }, [container, prefix, span, props.value, props.prefix, props.suffix])
  return (
    <div
      className={css}
      data-rebranding={"input"}
    >
      <div
        ref={container}
        className={"relative -mt-8 flex w-full overflow-hidden rounded px-4 pt-8"}
      >
        {props.prefix && (
          <label
            ref={prefix}
            className={"grow-2 text-gray-500"}
            htmlFor={id}
          >
            {props.prefix}
          </label>
        )}
        <input
          onKeyUp={e => {
            if (e.code === "Enter" && props.onEnterPress) {
              props.onEnterPress(e)
            }
          }}
          id={id}
          type={props.type}
          className={clsx(
            "kamae-input-input inline min-w-0 max-w-full grow bg-transparent focus:outline-none",
            props.disabled && "text-gray-500"
          )}
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          onChange={e => {
            if (props.onChange) {
              props.onChange(e)
            }
            if (props.onSearch) {
              const handler = props.onSearch
              clearTimeout(timeoutId)
              setTimeoutId(
                setTimeout(() => {
                  handler(e)
                }, 500) as unknown as number
              )
            }
          }}
          value={props.value}
          required
          name={props.name}
        />
        {props.suffix && (
          <>
            <span
              ref={span}
              className={"absolute -z-50 overflow-auto opacity-0"}
            >
              {props.value}
            </span>
            <label
              className={"absolute text-gray-400"}
              style={{ left: left }}
              htmlFor={id}
            >
              {props.suffix}
            </label>
          </>
        )}
        {props.placeholder && (
          <label
            data-rebranding={"placeholder"}
            className={cssPlaceholder}
            htmlFor={id}
          >
            {props.placeholder}
          </label>
        )}
      </div>
    </div>
  )
}
